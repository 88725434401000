// extracted by mini-css-extract-plugin
export var root = "PageBanner-module--root--ckWEC";
export var blue = "PageBanner-module--blue--bkRKL";
export var latte = "PageBanner-module--latte--NIb85";
export var purple = "PageBanner-module--purple--XI-ww";
export var pink = "PageBanner-module--pink--aDiPj";
export var breadCrumb = "PageBanner-module--breadCrumb--WXtHx";
export var header = "PageBanner-module--header--gmcNC";
export var subtitle = "PageBanner-module--subtitle--gcyaL";
export var small = "PageBanner-module--small--p9JqN";
export var medium = "PageBanner-module--medium--q4Jvf";
export var twitter = "PageBanner-module--twitter--OlbCh";
export var containerContent = "PageBanner-module--containerContent--mar8P";